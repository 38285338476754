import type { ImageProps } from '.';

import NextImage from 'next/image';

const CLOUDINARY_DOMAIN = `${process.env.CLOUDINARY_DOMAIN ?? ''}`

const getCloudinaryParams = ({ quality, width, height }: { quality?: number | `${number}`, width?: number | string, height?: number | string }): string => {

    let param: string = ''
    const q: string = quality ? 'q_' + quality : 'q_auto'

    if (width || height) {
        param += (width && `w_${width}`) ?? ''
        param += (height && `${width && ','}h_${height}`) ?? ''
    }

    return '' !== param ? [param, 'c_fit', q].join(',') : ''
}

const Image = ({ src: propSrc, width, height, quality, ...props }: ImageProps) => {
    let src = propSrc;
    let unoptimized = props.unoptimized

    if (undefined === src) {
        return (
            <NextImage src={'/no-image.png'} {...(width && { width })} {...(height && { height })} quality={quality} unoptimized={unoptimized} {...props} style={{ color: '' }} />
        )
    }

    const regex = new RegExp([process.env.COMMERCE_STORE_DOMAIN, process.env.COMMERCE_MEDIA_DOMAIN].join('|'), 'g')

    if (Array.isArray(src.match(regex)) && (process.env.COMMERCE_STORE_DOMAIN || process.env.COMMERCE_MEDIA_DOMAIN)) {

        const params = getCloudinaryParams({ quality, width, height })

        let url = new URL(src.replace(regex, CLOUDINARY_DOMAIN ?? ''))
        if ((!url.pathname.endsWith('svg') && params)) {
            url.searchParams.append('tx', params)
        }
        src = url.href

    } else if (process.env.WIKI_DOMAIN && src.startsWith('\/w\/images') || src.startsWith('\/w\/thumb.php') || src.startsWith('\/w\/index.php')) {
        unoptimized = true

        const params = getCloudinaryParams({ quality, width, height })
        const srcParams = src.split('?');

        src = `https://${CLOUDINARY_DOMAIN}` + encodeURI(src.replace('/index.php', '')) + (1 < srcParams.length ? '&' : '?') + (params ? 'tx=' + params : '')
    }

    return (
        <NextImage src={src} {...(width && { width })} {...(height && { height })} quality={quality} unoptimized={unoptimized} {...props} style={{ color: '' }} />
    )
}

export default Image;
